<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form ref="form" :model="form" label-width="120px">
      <el-form-item label="商户名">
        <el-input v-model="model" placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <el-form-item label="商户证件号">
        <el-input v-model="model" placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <el-form-item label="负责人">
        <el-input v-model="model" placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <el-form-item label="负责人证件号">
        <el-input v-model="model" placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <el-form-item label="手机号">
        <el-input v-model="model" placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <el-form-item label="所在地区">
        <el-input v-model="model" placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <el-form-item label="详细地址">
        <el-input v-model="model" placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <el-form-item label="分配比例">
        <el-input v-model="model" placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <el-form-item label="密码" v-show="!$route.params.id">
        <el-input v-model="model" placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" v-show="!$route.params.id">
        <el-input v-model="model" placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" size="small">保存</el-button>
        <el-button type="" size="small" @click="$router.back()">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'AddOrEdit',

  data() {
    return {}
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .el-form {
    .el-form-item {
      .el-input {
        width: 500px;
      }
    }
  }
}
</style>
